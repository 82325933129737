import React, { useState, useEffect } from "react";
import styles from "../../css/FaqSection.module.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const FaqSection = () => {
  // State for open FAQ and selected topic
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState("General"); // Default to 'General'

  // Dynamic FAQ data with topics
  const faqs = [
    { question: "What services does Dataandgrow offer?", answer: "We are a digital business transformation consultancy that solves human problems. We elevate customer experience, modernize organizations, and unlock value through tech and data by leveraging AI and digital technologies. Our IT services include AI research and development, VR/AR solutions, Big Data analytics, software development, cloud management, Unity gaming development, and DevOps.", topic: "General" },
    { question: "What types of products does Dataandgrow develop?", answer: "We specialize in creating IT solutions based on innovation that help make daily lives easier.", topic: "General" },
    { question: "Who are your typical clients?", answer: "We work with startups, SMEs, and large enterprises across industries, including gaming, healthcare, finance, and retail.", topic: "General" },
    { question: "Can your software products be customized?", answer: "Yes, all our products can be customized to meet specific requirements. We work closely with clients to ensure our solutions align with their business goals.", topic: "Product Information" },
    { question: "Do you offer support and maintenance for products?", answer: "Yes, we provide comprehensive support and maintenance options to ensure optimal performance, security, and updates for our products.", topic: "Product Information" },
    { question: "Do you provide Strategy Consultancy in product development?", answer: "We offer consultancy in digital transformation, IT roadmap planning, business process optimization, and tech-driven growth strategies to align technology with your business objectives.", topic: "Product Information" },
    { question: "Can you handle large-scale cloud migrations?", answer: "Yes, we have extensive experience with cloud migration projects of all sizes, offering assessment, planning, and migration services for a seamless transition.", topic: "Services" },
    { question: "How do you approach AI/ML projects?", answer: "We begin with a discovery phase to understand your data and objectives, followed by design, development, and deployment of models tailored to your needs, focusing on accuracy and scalability.", topic: "Services" },
    { question: "What is your VR/AR development process?", answer: "Our process includes initial concept development, user experience design, 3D modeling, and testing to deliver immersive AR/VR experiences.", topic: "Services" },
    { question: "How quickly can a new project be started?", answer: "We can typically begin within 1-2 weeks, depending on project requirements and current availability.", topic: "Project Management" },
    { question: "What is the onboarding process like?", answer: "We start with a kickoff meeting to align goals and expectations, followed by a discovery phase to finalize requirements and project timelines.", topic: "Project Management" },
    { question: "Do you assign dedicated project managers?", answer: "Yes, each client is assigned a dedicated project manager who serves as the primary point of contact and ensures seamless communication.", topic: "Project Management" },
    { question: "Do you offer 24/7 support?", answer: "We offer flexible support options, including 24/7 support plans for mission-critical systems.", topic: "Support" },
    { question: "How do you handle urgent issues?", answer: "For clients with support agreements, we have protocols to address urgent issues within a defined timeframe based on severity and priority.", topic: "Support" },
    { question: "Can we reach support via phone, chat, or email?", answer: "Yes, we offer multiple support channels, including phone, email, and chat support, depending on your preference and service agreement.", topic: "Support" },
    { question: "How do you ensure data privacy?", answer: "We adhere to industry-standard data privacy regulations and best practices, ensuring secure data storage, transmission, and handling.", topic: "Security & Compliance" },
    { question: "Are your products compliant with GDPR/CCPA?", answer: "Yes, we design our products to be compliant with major data protection regulations, including GDPR and CCPA.", topic: "Security & Compliance" },
    { question: "What security certifications does your team hold?", answer: "Our team includes certified professionals in cybersecurity, cloud security, and data protection.", topic: "Security & Compliance" },
    { question: "What technologies do you use for software development?", answer: "We use a range of technologies, including Java, React, Python, Unity, AWS, and Google Cloud.", topic: "General" },
    { question: "Can you work with our existing technology stack?", answer: "Yes, our team is proficient in various tech stacks and can integrate with your existing technology as needed.", topic: "General" },
    { question: "How do you ensure quality in your deliverables?", answer: "We follow a rigorous quality assurance process, including automated testing, code reviews, and performance evaluations to ensure high-quality output.", topic: "General" },
  ];

  // Extract unique topics dynamically from FAQ data
  const topics = ["All", ...new Set(faqs.map(faq => faq.topic))];

  // Toggle FAQ open/close
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // Filter FAQs based on selected topic
  const filteredFaqs = faqs.filter(faq => selectedTopic === "All" || faq.topic === selectedTopic);

  // Reset openIndex when selectedTopic changes
  useEffect(() => {
    setOpenIndex(null);
  }, [selectedTopic]);

  return (
    <section id="FAQs" className={styles.faqSection}>
      <DecorativeShapes />
      <CardSlotButton />
      
      <div className={styles.faqsquestions}>
        <h2 className={styles.heading}>Frequently Asked Questions</h2>
        
        {/* Dynamic Filter Buttons */}
        <div className={styles.filterButtons}>
          {topics.map((topic) => (
            <button
              key={topic}
              onClick={() => setSelectedTopic(topic)}
              className={selectedTopic === topic ? styles.activeButton : ""}
            >
              {topic}
            </button>
          ))}
        </div>

        {/* FAQ List */}
        <div className={styles.faqList}>
          {filteredFaqs.map((faq, index) => (
            <div key={index} className={styles.faqItem}>
              <div
                className={styles.faqQuestion}
                onClick={() => toggleFAQ(index)}
              >
                <h4>{faq.question}</h4>
                {openIndex === index ? (
                  <FaChevronUp className={styles.icon} />
                ) : (
                  <FaChevronDown className={styles.icon} />
                )}
              </div>
              {openIndex === index && (
                <div className={styles.faqAnswer}>
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Helper Components
const DecorativeShapes = () => (
  <>
    <div className={styles.faqsshape1}></div>
    <div className={styles.faqsshape2}></div>
  </>
);

const CardSlotButton = () => (
  <div className={styles.faqscard}>
    <div className={styles.faqscardlayout}>
      <div className={styles.nav}>• Slot Available</div>
      <div className={styles.cardtext}>Have a particular thought?</div>
      <div className={styles.faqscardblock}><div className={styles.faqscardblockheading}>Schedule a 15-minute call</div>
      <div className={styles.faqscardblockbody}> with our  <a href="#contact" className={styles.linkp}>Customer Success Manager</a></div></div>
      <div className={styles.faqscardblock}><div className={styles.faqscardblockheading}>For any other question</div>
      <div className={styles.faqscardblockbody}>
    We’re here to help! Don’t hesitate to <a href="#contact" className={styles.linkp}>contact us</a>.
  </div></div>
    </div>
  </div>
);

export default FaqSection;
