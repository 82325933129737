import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import emailjs from "emailjs-com";
import styles from "../../css/ChatbotButton.module.css"; // Import CSS module

const ChatbotButton = () => {
  const [isChatVisible, setChatVisible] = useState(false);
  const [step, setStep] = useState(1); // Tracks the current step of the chat
  const [formData, setFormData] = useState({
    userName: "",
    userEmail: "",
    userMessage: "",
  });
  const [errors, setErrors] = useState({
    userName: "",
    userEmail: "",
    userMessage: "",
  });

  // Auto-open chatbot after 1 minute
  useEffect(() => {
    const timer = setTimeout(() => {
      setChatVisible(true);
    }, 60000); // 1 minute in milliseconds

    // Cleanup timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear the error message when user types
  };

  const validateStep = () => {
    let isValid = true;
    let newErrors = { ...errors };

    if (step === 2 && !formData.userName.trim()) {
      newErrors.userName = "Name is required.";
      isValid = false;
    }

    if (
      step === 3 &&
      (!formData.userEmail.trim() ||
        !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.userEmail))
    ) {
      newErrors.userEmail = "Please enter a valid email.";
      isValid = false;
    }

    if (step === 4 && !formData.userMessage.trim()) {
      newErrors.userMessage = "Message is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleNextStep = () => {
    if (validateStep()) {
      if (step < 4) setStep(step + 1); // Move to the next step if validation passes
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const userId = "0xK1r-EyCuBbXjjuh"; // Your public User ID
    const serviceId = "service_zoukfh3"; // Your EmailJS Service ID
    const templateId = "template_ixm8862"; // Your EmailJS Template ID

    emailjs
      .send(serviceId, templateId, formData, userId)
      .then((response) => {
        console.log("Email sent successfully!", response.status, response.text);
        alert("Message sent successfully!");
        setFormData({ userName: "", userEmail: "", userMessage: "" }); // Reset form
        setStep(1); // Reset to step 1
        setChatVisible(false); // Close chat
      })
      .catch((err) => {
        console.error("Failed to send email:", err);
        alert("Failed to send message. Please try again later.");
      });
  };

  return (
    <>
      {/* Draggable Chatbot Button */}
      <Draggable>
        <button
          className={styles.chatbotButton}
          onClick={() => setChatVisible(!isChatVisible)}
        >
          💬
          <div className={styles.blinkingWave}></div>
        </button>
      </Draggable>

      {/* Chatbot */}
      {isChatVisible && (
        <div className={styles.chatWindow}>
          <div className={styles.chatHeader}>
            <button
              className={styles.closeButton}
              onClick={() => setChatVisible(false)}
            >
              ✖
            </button>
            <h4 className={styles.chatTitle}>Chat with Dataandgrow</h4>
          </div>
          <div className={styles.chatBody}>
            {step === 1 && (
              <div className={styles.chatMessage}>
                <p>Hi, how can I help you today?</p>
                <button className={styles.nextButton} onClick={handleNextStep}>
                  Start
                </button>
              </div>
            )}
            {step === 2 && (
              <div className={styles.chatMessage}>
                <p>May I know your name?</p>
                <input
                  type="text"
                  name="userName"
                  placeholder="Your Name"
                  className={styles.inputField}
                  value={formData.userName}
                  onChange={handleChange}
                  required
                />
                {errors.userName && (
                  <p className={styles.error}>{errors.userName}</p>
                )}
                <button className={styles.nextButton} onClick={handleNextStep}>
                  Next
                </button>
              </div>
            )}
            {step === 3 && (
              <div className={styles.chatMessage}>
                <p>Can you please share your email?</p>
                <input
                  type="email"
                  name="userEmail"
                  placeholder="Your Email"
                  className={styles.inputField}
                  value={formData.userEmail}
                  onChange={handleChange}
                  required
                />
                {errors.userEmail && (
                  <p className={styles.error}>{errors.userEmail}</p>
                )}
                <button className={styles.nextButton} onClick={handleNextStep}>
                  Next
                </button>
              </div>
            )}
            {step === 4 && (
              <div className={styles.chatMessage}>
                <p>If you have any query, please feel free to share.</p>
                <textarea
                  name="userMessage"
                  placeholder="Type your message here"
                  rows="3"
                  className={styles.inputField}
                  value={formData.userMessage}
                  onChange={handleChange}
                  required
                ></textarea>
                {errors.userMessage && (
                  <p className={styles.error}>{errors.userMessage}</p>
                )}
                <button
                  type="submit"
                  className={styles.submitButton}
                  onClick={handleSubmit}
                >
                  Send Message
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ChatbotButton;
