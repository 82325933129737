import React from 'react';
import { DonutCursorProvider, DonutConsumer } from 'react-donut-cursor';  // Import the donut cursor package

import Navbar from '../Components/Navbar';
import FirstVideoSection from './1_VideoSection'; 
import ContactSection from '../Components/Contact'; 
import CreativeTeamSection from './CreativeTeamSection';
import SecondAbout from './2_AboutSection';
import ThirdServices from './3_Services';
import FourthClient from './4_Client';
import FAQs from "./5_FAQs";
import Insight from "./Insight"; 
import ChatbotButton from './ChatbotButton';// Import the test insight component

const Home = () => {
  return (
    // Wrap the entire page with DonutCursorProvider to apply the custom cursor globally
    
        <div>
          <Navbar />
          <FirstVideoSection />
          <SecondAbout />
          <ThirdServices />
          <FourthClient />
          <FAQs />
          <Insight /> 
          <CreativeTeamSection />
          <ContactSection />
          <ChatbotButton/>
        </div>
      
  );
};

export default Home;
